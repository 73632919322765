var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-md-row flex-column",
                    staticStyle: { gap: "0.5rem" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("v-select", {
                          staticClass: "v-select-filter",
                          attrs: {
                            placeholder: "Select company..",
                            options: _vm.allCompanies,
                          },
                          on: { input: _vm.companyFilter },
                          model: {
                            value: _vm.selectedCompany,
                            callback: function ($$v) {
                              _vm.selectedCompany = $$v
                            },
                            expression: "selectedCompany",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.pages > 1
                      ? _c(
                          "div",
                          [
                            _c("CPagination", {
                              attrs: {
                                align: "end",
                                dots: false,
                                pages: _vm.pages,
                                "active-page": _vm.activePage,
                              },
                              on: {
                                "update:activePage": function ($event) {
                                  _vm.activePage = $event
                                },
                                "update:active-page": function ($event) {
                                  _vm.activePage = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "table-filter": { external: true, lazy: true, placeholder: "..." },
          tableFilterValue: _vm.search,
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No payments found!",
          },
        },
        on: {
          "update:tableFilterValue": function ($event) {
            _vm.search = $event
          },
          "update:table-filter-value": [
            function ($event) {
              _vm.search = $event
            },
            _vm.tableSearch,
          ],
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" #" + _vm._s(item?.id) + " ")])]
            },
          },
          {
            key: "company",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _vm._v(" #" + _vm._s(item.company?.id) + " "),
                    _c("CLink", { staticClass: "font-weight-bold" }, [
                      _vm._v(" " + _vm._s(item.company?.name) + " "),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "invoice",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" " + _vm._s(item.invoice.ivNr) + " ")])]
            },
          },
          {
            key: "apamount",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    " " + _vm._s(_vm._f("toCurrency")(item.apamount)) + " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "apdate-filter",
            fn: function () {
              return [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dateFilter,
                      expression: "dateFilter",
                    },
                  ],
                  staticClass: "w-min",
                  attrs: { type: "date" },
                  domProps: { value: _vm.dateFilter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.dateFilter = $event.target.value
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "apdate",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" " + _vm._s(item.apdate) + " ")])]
            },
          },
          {
            key: "apnote",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" " + _vm._s(item.apnote) + " ")])]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "p-2" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: { size: "sm", color: "danger", shape: "pill" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item, index)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-trash" } })],
                      1
                    ),
                    !item.invoice.is_paid
                      ? _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  html: true,
                                  content: "Add Payment",
                                  placement: "right",
                                },
                                expression:
                                  "{\n            html: true,\n            content: 'Add Payment',\n            placement: 'right',\n          }",
                              },
                            ],
                            staticClass: "ml-1",
                            attrs: {
                              size: "sm",
                              color: "warning",
                              shape: "pill",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addPayment(item.invoice)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-euro" } })],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("payments-aside", {
        attrs: { invoice: _vm.selectedInvoice },
        on: {
          "update:invoice": function ($event) {
            _vm.selectedInvoice = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }